<template>
    <v-col class="pr-0 pb-0 mb-0">
        <v-row justify="center">
            <v-card
                flat
                :width="width"
                align="center"
                justify="center"
                tile
            >
                <span 
                    class="mr-3 text-subtitle-1 primary--text font-weight-bold"                                         
                > {{ studentName }}</span>                                 
                <span 
                    class="text-subtitle-1 primary--text font-weight-bold"
                >{{ classId }}</span>
            </v-card>
        </v-row>

        <v-row class="mb-2" justify="center">
            <v-card                                    
                :width="width"
                flat
                tile                                    
            >
                <v-row>
                    
                    <v-col
                        align="center"
                        justify="center"
                        v-for="item in academicPeriod"
                        :key="item.title"
                        class="pa-0"
                        cols="4"                        
                    >
                        <div 
                            class="text-caption blue--text"
                        >{{item.title}}</div>
                        <div 
                            class="text-caption green--text"
                        >{{item.value}}</div>
                    </v-col>
                </v-row>
            </v-card>                                 
        </v-row>

        <v-row justify="center">
            <v-card                
                :width="width"
                flat
            >
                <div>
                    <v-list class="py-0" dense>
                        <v-list-item
                            v-for="item in recordSummary"
                            :key="item.title"
                            
                        >
                            <v-list-item-content class="pt-0 pb-2">
                                <v-list-item-title 
                                    class="text-caption"
                                >
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-card                               
                                flat
                                class="pb-2"
                            >
                                <v-text-field
                                    v-if="!item.select && item.number"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled" 
                                    @blur="updateRecord"
                                    @keypress="numberValidation"
                                    dense
                                    outlined
                                    hide-details
                                    type="number"
                                    min=0
                                    max=100
                                    oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min" 
                                    v-model="studentRecord[item.model]"
                                    height="20"
                                    class="text-caption pa-0"
                                ></v-text-field>

                                <v-text-field
                                    v-if="!item.select && !item.number"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled" 
                                    @blur="updateRecord"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="studentRecord[item.model]"
                                    height="20"
                                    class="text-caption pa-0"
                                ></v-text-field>

                                <v-select
                                    v-else-if="item.model == 'times_late'"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled"
                                    :items="grades"
                                    item-text="grade"
                                    item-value="grade"                                    
                                    v-model="studentRecord[item.model]"
                                    hide-details                                    
                                    outlined
                                    class="text-caption text-center"
                                    @change="updateRecord"
                                    chips
                                    deletable-chips 
                                ></v-select>

                                <v-select
                                    v-else-if="item.select"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled"
                                    :items="grades2"
                                    item-value="grade"
                                    item-text="title"                                    
                                    v-model="studentRecord[item.model]"
                                    hide-details                                    
                                    outlined
                                    class="text-caption text-center"
                                    @change="updateRecord"
                                    chips
                                    deletable-chips 
                                >                                   
                                </v-select>
                            </v-card> 
                        </v-list-item>
                    </v-list>
                </div>                                     
            </v-card>
        </v-row>

        <v-row>
            <v-card :width="width" flat class="px-4" height="140"></v-card>
        </v-row>

        <v-row justify="center" >
            <v-card :width="width" flat class="px-4 ">
                <v-row>
                    <v-col class="d-flex justify-space-between pt-0" >
                
                        <v-btn 
                            outlined
                            text
                            height="20"
                            width="40"
                            color="primary"
                            @click="firstRecord"
                            :disabled="!pagination.prev_page" 
                        >
                            <v-icon>mdi-skip-previous</v-icon>
                        </v-btn>

                        <v-btn 
                            outlined
                            text
                            height="20"
                            width="40"
                            color="primary"
                            @click="previousRecord"
                            :disabled="!pagination.prev_page" 
                        >
                            <v-icon large>mdi-menu-left</v-icon>
                        </v-btn>
               
                        <v-btn 
                            outlined
                            text
                            height="20"
                            width="40"
                            color="primary"
                            @click="nextRecord"
                            :disabled="!pagination.next_page"
                        >
                            <v-icon large>mdi-menu-right</v-icon>
                        </v-btn>

                        <v-btn 
                            outlined
                            text
                            height="20"
                            width="40"
                            color="primary"
                            @click="lastRecord"
                            :disabled="!pagination.next_page"
                        >
                            <v-icon>mdi-skip-next</v-icon>
                        </v-btn>
                    
                    </v-col>
                </v-row>
            </v-card>
        </v-row>

        <!-- <v-row class="mr-0">
            <v-card
                flat
                width="350"
            >
                <v-row
                    style="position:absolute; top:-15px; right:30px" 
                    class="ml-0 mb-1"
                >
                    
                    <v-card 
                        width="110" 
                        flat
                        class="ml-auto mr-2"
                        style="font-size:0.55rem; text-align: center"
                    >
                        All Classes
                    </v-card>
                    <v-card 
                        width="110" 
                        flat 
                        style="font-size:0.55rem; text-align: center"                           
                    >
                        Current Class
                    </v-card>                       
                </v-row>

                <v-row class="pl-3 mx-0 mb-2">
                    <v-card 
                        flat 
                        style="font-size:0.7rem"
                        class="mr-2"
                        width="82"
                    >
                        Possible Att.
                    </v-card>
                    <v-card 
                        width="110" 
                        flat
                        class="mr-2"
                    >
                        <v-text-field
                            v-model="possibleAttendanceAll"                                
                            outlined
                            hide-details
                            dense
                            style="font-size:0.7rem"
                            type="number"
                            @blur="changePossibleAttendance('All')"
                            :disabled="!admin"
                        ></v-text-field>
                    </v-card>
                    <v-card 
                        width="110" 
                        flat                            
                    >
                        <v-text-field
                            v-model="studentRecord.possible_attendance"
                            outlined
                            hide-details
                            dense
                            style="font-size:0.7rem"
                            type="number"
                            @blur="changePossibleAttendance(studentRecord.class_id)"
                            :disabled="!admin"
                        ></v-text-field>
                    </v-card>                       
                </v-row>

                <v-row class="pl-3 ml-0 mb-2">
                    <v-card 
                        flat 
                        style="font-size:0.7rem"
                        class="mr-2"
                        width="82"
                    >
                        School Reopens
                    </v-card>
                    <v-card 
                        width="110" 
                        flat
                        class="mr-2"
                    >                            
                        <date-picker 
                            v-on:change-date="newTermDate($event,'All')"
                            v-bind:date="newTermBeginningAll"
                            :disabled="!admin"                                
                        ></date-picker>                           
                    </v-card>
                    <v-card 
                        width="110"
                        style="font-size:0.7rem" 
                        flat                            
                    >
                        <date-picker 
                            v-on:change-date="newTermDate($event, studentRecord.class_id)"
                            v-bind:date="studentRecord.new_term_beginning"
                            :disabled="!admin"
                        ></date-picker> 
                    </v-card>                       
                </v-row>

                <v-row class="pl-3 ml-0">
                    <v-card 
                        flat 
                        style="font-size:0.7rem"
                        class="mr-2"
                        width="82"
                    >
                        Change Class
                    </v-card>
                    <v-card 
                        width="110" 
                        flat
                        class="mr-2"
                    >
                        <v-select
                            outlined
                            hide-details
                            dense
                            :items="classOptions"
                            class="change-class"
                            style="font-size:0.6rem"
                            v-model="studentRecord.class_id"
                            @change="changeClass"
                            :disabled="!admin"
                        ></v-select>
                    </v-card>
                    <v-card 
                        width="110" 
                        flat                            
                    >
                        <v-btn                                
                            color="primary"
                            x-small
                            height="30"
                            block
                            @click="deleteRecord"
                            :disabled="!admin"                                
                        >
                            Delete
                        </v-btn>
                    </v-card>                       
                </v-row>

            </v-card>
        </v-row> -->

        <v-row justify="center">
            <v-card                                
                class="mb-2 px-4 "
                flat
                :width="width"
            >                
                <v-row>
                    <v-col class="py-0">
                        <v-btn                        
                            outlined
                            color="primary"                        
                            @click="searchStudent"
                            small                        
                            class="mr-2"
                        >
                            <div 
                                class="d-flex flex-column"
                                style="font-size:0.6rem"
                            >
                                <span>Search</span>
                                <!-- <span>Student</span> -->
                            </div>
                            
                        </v-btn>

                        <v-btn                        
                            outlined
                            color="primary"                        
                            @click="nextClass"
                            small                        
                            class="mr-2"
                        >
                            <div 
                                class="d-flex flex-column"
                                style="font-size:0.6rem"
                            >
                                <span>Next</span>
                                <!-- <span>Class</span> -->
                            </div>
                            
                        </v-btn>

                         <v-btn                        
                            outlined
                            color="primary"                        
                            @click="printOne"
                            small                        
                            
                        >
                            <div 
                                class="d-flex flex-column"
                                style="font-size:0.6rem"
                            >
                                <span>Print One</span>
                                <!-- <span>Class</span> -->
                            </div>
                            
                        </v-btn>
                    </v-col>
                </v-row>                      
            </v-card>

            <v-card class="px-4 mt-2" flat :width="width">
                <v-row>
                    <v-col class="py-0">
                        <v-textarea                                       
                            label="Form Teacher's Comments" 
                            outlined
                            placeholder=" "                           
                            persistent-hint
                            dense
                            rows="3"
                            :value="value"                        
                            counter                                            
                            class="text-caption"
                            :rules="rules"                      
                            no-resize
                            maxlength="215"
                            v-model="studentRecord.comments"                            
                            @blur="saveTeacherComment"
                            @click="openBottomSheet('comments')"
                            :readonly="disabled"
                            :filled="disabled"
                        >                        
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card>    
        </v-row>

        <!-- <v-row
            class="mt-3"
        >
            <v-card
                flat
                :width="width"
                class="d-flex justify-space-between pl-4"
            >
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="30"                
                    color="primary"
                    @click="previousRecord"
                    :disabled="!pagination.prev_page"                
                >
                    <div class="d-flex flex-column"> 
                        <span>PREV</span>
                    </div>                                         
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2"
                    width="15"
                    height="30"
                    outlined
                    disabled                
                    text                
                >
                    <span class="primary--text font-weight-bold">{{pagination.current_page}} of {{ pagination.last_page}}</span>                                                        
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="30"
                    dark
                    color="primary"
                    @click="nextRecord"
                    :disabled="!pagination.next_page"
                >
                    <div class="d-flex flex-column">
                        <span>NEXT</span>
                    </div>                                         
                </v-btn>
            </v-card>    
        </v-row> -->

        <v-overlay
            absolute
            :value="overlay"
            
            opacity="0.6"
        >
            <v-expand-transition>
                <v-card
                    max-width="600"
                    class="mx-auto pa-6"
                    transition="scroll-y-transition"
                    v-show="expand"
                    light
                >
                    <v-card-title
                        class="mb-6"
                    >
                        <v-text-field
                            v-model="search"
                            label="Search for Student Record"
                            append-icon="mdi-magnify"
                            class="mx-4"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="table1Records"
                        :search="search"
                        fixed-header
                        height="40vh"
                        :options="options"
                        dense
                    >
                        <template
                            v-slot:[`item.actions`]="{ item }"
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="viewRecord(item)"
                            >
                                View Record
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            outlined
                            @click="cancelSearch"
                        >
                            Cancel Search
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-overlay>

    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
// import DatePicker from './DatePicker.vue';
export default {
    components: {
        // DatePicker,
    },

    created(){
        this.initialize();
        //console.log(this.extraCurricularFields(1));
    },
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getSelectedStudentRecord',            
            academicYear: 'termReports/getAcademicYear',
            currentTerm: 'termReports/getCurrentTerm',
            formTeacherClass: 'termReports/getFormTeacherClass',
            codedComments: 'termReports/getCodedComments',
            table1Records: 'termReports/getTable1Records',
            pagination: 'termReports/getPagination',
            formClasses: 'termReports/getFormClasses',
            admin: 'auth/getAdmin',
        }),

        studentName () {
            return this.studentRecord.first_name + ' ' + this.studentRecord.last_name;
        },

        classId () {
            return this.studentRecord.class_id;
        },

        recordSummary () {
            return [
                {title: 'Student\'s ID', model: 'student_id', select: false, disabled: true, section: 1},
                {title: 'Total No. of Sessions', model: 'possible_attendance', select: false, disabled: true, section: 1},
                {title: 'Sessions Absent', model: 'times_absent', select: false, disabled: false, section: 1},
                {title: 'Times Late', model: 'times_late', select: false, disabled: false, section: 1},
                // {title: 'Diligence', model: 'dil', select: true, disabled: false, section: 1},
                // {title: 'Progress', model: 'pro', select: true, disabled: false, section: 1},
                // {title: 'Attitude to Authority', model: 'auth', select: true, disabled: false, section: 1},
                // {title: 'Attitude to Peers', model: 'peers', select: true, disabled: false, section: 1},
                // {title: 'Computer Literacy', model: 'computer_literacy', number: true, disabled: false, section: 1},
            ]
        },

        termName () {
            let termName = '';
            if(this.currentTerm == 1) termName =  '1';
            if(this.currentTerm == 2) termName = '2';
            if(this.currentTerm == 3) termName = '3';
            return termName
        },             
        
        
    },
    watch: {
        studentRecord: {
            handler () {
                this.setDisabledFields();               
            }
        },

        formClasses: {
            handler (val) {
                // console.log(val);
                if(val){
                    this.setFormClasses();
                }
                
            }
        }
    },
    data: () => ({
        academicPeriod: [
            { title: 'Year', value: '' },
            { title: 'Term', value: '' },
            { title: 'Test', value: 'End of Term' },
        ],
        grades: [
            {grade: 'A', scale: '80 - 100'},
            {grade: 'B', scale: '70 - 79'},
            {grade: 'C', scale: '60 - 69'},
            {grade: 'D', scale: '55 - 59'},
            {grade: 'E', scale: '50 - 54'},
            {grade: 'U', scale: '0 - 49'},
                     
        ],
        grades2: [
            {grade: 'A', title: 'Excellent'},
            {grade: 'B', title: 'Good'},
            {grade: 'C', title: 'Making Progress'},
            {grade: 'D', title: 'Needs Improvement'},
            {grade: 'E', title: 'Unsatisfactory'},           
        ],
        
        headers: [
            { text: 'Student ID', align: 'center', value: 'student_id', width: '75' },
            { text: 'First Name', align: 'left', value: 'first_name', width: '100' },
            { text: 'Last Name', align: 'left', value: 'last_name', width: '100' },
            { text: '', align: 'center', value: 'actions', sortable: false, width: '150' },
        ],
        options: {
            itemsPerPage: -1,
        }, 
       
        disabled: true,        
        expand: false,
        overlay: false,
        search: '',
        width: 300,
        value: ' ',
        rules: [],
        classOptions: [],
        newTermBeginningAll: '',
        possibleAttendanceAll: null        
    }),
    methods: {
        ...mapActions({ 
            postTable1Record: 'termReports/postTable1Record',
            getTable2Records: 'termReports/getTable2Records', 
            setErrorResponse: 'termReports/setErrorResponse',
            postChangeClass: 'admin/changeClass',
            postPossibleAttendance: 'admin/postPossibleAttendance',
            postNewTermBeginning: 'admin/postNewTermBeginning',             
        }),        
        ...mapMutations({         
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setPagination: 'termReports/setPagination',           
            setLoading: 'termReports/setEditViewTermReportsLoading',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects', 
            setExpand: 'termReports/setEditViewTermReportsExpand',
            setBottomSheetModel: 'termReports/setBottomSheetModel',
            setBottomSheetTitle: 'termReports/setBottomSheetTitle',
            setCommentSheet: 'termReports/setCommentSheet',
            setPossibleAttendance: 'admin/setPossibleAttendance',
            setNewTermBeginning: 'admin/setNewTermBeginning',
            setChangeClassRecord: 'admin/setChangeClassRecord',
            setSnackbar: 'admin/setSnackbar', 
            setSelectedTable1Record: 'admin/setSelectedTable1Record',
            setDialogDelete: 'admin/setDialogDelete',
            setExpandTerms: 'termReports/setExpandTerms',
            setSrc: 'termReports/setSrc', 
        }),

        setRecordSummary () {
            //
        },

        async updateRecord () {
            console.log('updating...');
            console.log(this.studentRecord);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);           
            
            try{
                let response = await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                console.log(response);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },

        initialize () {            
            this.academicPeriod[0].value = this.academicYear;
            this.academicPeriod[1].value = this.termName;            
            //console.log(this.codedComments);            
        },

        searchStudent () {
            this.overlay = true;
            setTimeout(() => {
                this.expand = true;
            })
        },

        viewRecord (item) {
            //console.log(item);
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
            let recordIndex = this.table1Records.indexOf(item);
            this.recordNavigation(++recordIndex);
        },

        cancelSearch () {
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
        },

        async recordNavigation (pagination) {            
            this.setOverlay(true);
            this.setLoading(true);
            
            this.makePagination(pagination);
            this.setSelectedStudentRecord(this.table1Records[--pagination]);
            try {
                const { data } = await this.getTable2Records();                             
                this.setTable2Records(data.table2_records);
                this.setStudentSubjects(data.student_subjects);                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
            this.setOverlay(false);
            this.setLoading(false);            
        },

        makePagination (data) {
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;
            
            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },       

        async previousRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.prev_page);
            console.log('previous');
        },

        firstRecord () {
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(1);
            console.log('first');
        },

        nextRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.next_page);
            console.log('next');
        },

        lastRecord () {
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.last_page);
            console.log('last');
        },
        
        nextClass () {
            this.setOverlay(true);
            this.setExpand(true);
        },

        setDisabledFields () {
            this.disabled = false;
            console.log(`form teacher class: ${this.formTeacherClass}`);
            this.recordSummary.forEach(element => {
                if(                           
                    element.model != 'student_id' &&
                    element.model != 'possible_attendance'
                )
                {                            
                    element.disabled = false;
                }
                else{                            
                    element.disabled = true;
                }
            })
            
            if(
                this.formTeacherClass != this.studentRecord.class_id
                && !this.admin
            )
            {                
                this.disabled = true;                    
                this.recordSummary.forEach(element => {
                    element.disabled = true;
                })
                
            }
                
        },

        saveTeacherComment ( ){            
            this.updateRecord();
        },

        openBottomSheet (model) {
            this.setBottomSheetModel(model); 
            if(model == 'comments'){
                this.setBottomSheetTitle('General Comments');
            }
            // else{
            //     this.setBottomSheetTitle('General Remarks: Conduct');
            // }          
            if(!this.disabled) this.setCommentSheet(true);
            //console.log(`sheet: ${ this.sheet }`);
        },

        async changePossibleAttendance (formClass) {
            console.log(formClass);            
            this.setOverlay(true);
            this.setLoading(true);

            let snackbar = {};

            if(formClass == 'All'){
                this.setPossibleAttendance({
                    possible_attendance: this.possibleAttendanceAll,
                    class_id: null,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `Possible attendance set to ${this.possibleAttendanceAll} for all classes.`
            }
            else{
                this.setPossibleAttendance({
                    possible_attendance: this.studentRecord.possible_attendance,
                    class_id: this.studentRecord.class_id,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                })
                snackbar.text = `Possible attendance set to ${this.studentRecord.possible_attendance} for ${this.studentRecord.class_id}.`
            }

            try {
                let response = await this.postPossibleAttendance();
                if(formClass == 'All') 
                this.studentRecord.possible_attendance = this.possibleAttendanceAll;
                snackbar.color = 'primary';
                snackbar.display = true;
                this.setSnackbar(snackbar);  
                console.log(response.status);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                snackbar.color= 'red';
                snackbar.display = true;
                snackbar.text = 'An Error Has Occured';
                this.setSnackbar(snackbar);
            }
            
            this.setOverlay(false);
            this.setLoading(false);
        },
        
        async changeClass () {
            this.setOverlay(true);
            this.setLoading(true);            
            this.setChangeClassRecord({
                student_id: this.studentRecord.student_id,
                class_id: this.studentRecord.class_id
            })
            try {
                let response = await this.postChangeClass();
                this.setSnackbar({
                    text: `Class changed to ${this.studentRecord.class_id}`,
                    display: true,
                    color: 'primary',
                });               
                console.log(response);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });              
            }            
            this.setOverlay(false);
            this.setLoading(false);
        },

        async newTermDate (e, formClass) {
            this.setOverlay(true);
            this.setLoading(true);
            this.studentRecord.new_term_beginning = e;

            let snackbar = {};

            if(formClass == 'All'){
                this.setNewTermBeginning({
                    new_term_beginning: e,
                    class_id: null,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `New Term Beginning set to ${e} for all classes.`
            }
            else{
                this.setNewTermBeginning({
                    new_term_beginning: e,
                    class_id: this.studentRecord.class_id,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `New Term Beginning set to ${e} for ${formClass}.`
            }
            // console.log(`Date: ${e}, class: ${formClass}`);

            try {
                let response = await this.postNewTermBeginning();
                console.log(response);
                snackbar.color = 'primary';
                snackbar.display = true;
                this.setSnackbar(snackbar);  
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                snackbar.color= 'red';
                snackbar.display = true;
                snackbar.text = 'An Error Has Occured';
            }

            this.setOverlay(false);
            this.setLoading(false);
            
        },

        setFormClasses () {
            // console.log(this.formClasses[0])
            this.formClasses.forEach(value => {
                value.items.forEach(item => {
                    this.classOptions.push(item.title);
                })
                // console.log(value);
            });
            
        },

        deleteRecord () {
            // console.log(this.table2Records);
            this.setSelectedTable1Record(this.studentRecord);
            if(this.table2Records.length != 0){
                this.setDialogDelete({
                    display: true,
                    text: 'Cannot delete record with existing subject records. Please delete all subject records first.',
                    cancel: false,
                    deleteSubject: false,
                    deleteStudent: false,
                    ok: true
                })
            }
            else{
                console.log(this.studentRecord);
                this.setDialogDelete({
                    display: true,
                    text: `Delete ${this.studentRecord.first_name} ${this.studentRecord.last_name} ?`,
                    cancel: true,
                    deleteSubject: false,
                    deleteStudent: true,
                    ok: false
                })
            }
        },

        numberValidation ($event) {
            if($event.keyCode < 48 || $event.keyCode > 57)
            return $event.preventDefault();
        },

        printOne () {
            this.setOverlay(true);
            this.$nextTick(() => {
                this.setExpandTerms(true);
            });
            
            this.setSrc({
                baseUrl: process.env.VUE_APP_API_URI  + 'api/report-card',
                studentId: this.studentRecord.student_id,
                year: this.studentRecord.year,
                term: this.studentRecord.term,
                report: 'report-card'               
            });  
        }
        
    },

   
}
</script>

<style scoped>
    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    ::v-deep .v-list--dense .v-list-item{
        min-height: 25px;
    }

    .v-btn__content{
        font-size: 0.9em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 2px;        
    }

    ::v-deep .v-btn:not(.v-btn--round).v-size--small{
        height: 24px; 
    }

    ::v-deep .v-select__selections{
        height: 20px;
        flex-wrap: nowrap;
        /*padding: 0 !important;*/
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    ::v-deep .v-select__selection--comma{
        margin: 0 4px 4px 0;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-chip.v-size--default{
        height: 15px;
        font-size: 10px;
        margin: 0;
    }
    
    ::v-deep .v-select .v-input__control{
        height: 20px;
    }

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
        min-height: 8px;
    }

    ::v-deep .v-select.v-select--chips .v-select__selections{
        min-height: 8px;
    }

    ::v-deep .v-select__slot .v-input__append-inner{
        padding-left: 0;
    }

    /* ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 2px;
    } */

    ::v-deep .v-btn:not(.v-btn--round).v-size--default{
        min-width: 25px;
        padding: 0;
    }

    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 3px;
    }

    ::v-deep .v-label.v-label--active.theme--light{
        padding-left: 12px;
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    -webkit-appearance: textfield;
    text-align: center;
    }
</style>